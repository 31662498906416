<template>
  <div class="">
    <h3>プロフィール</h3>
    <b-card>
      <b-alert
        v-if="showAlert"
        variant="danger"
        show
      >
        <div class="alert-body mt-1 mb-2">
          <div class="">
            <div class="">
              動画を視聴するためには、プロフィールの入力が必要です。
            </div>
          </div>
        </div>
      </b-alert>
      <div
        v-if="profile.name"
        class=""
      >
        <div class="">
          {{ profile.name }}({{ profile.nameKana }}) 様
        </div>
        <div class="mt-1 text-sub">
          <small>登録：{{ formatDateMinutes(profile.insertTime) }}</small>
        </div>
      </div>
      <div class="text-right">
        <b-button
          variant="outline-primary"
          to="/student/profile-edit"
          size="sm"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span>編集する</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BAlert, BButton,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import formatDateMinutes from '@/components/js/filter/formatDateMinutes'
import useProfile from '@/components/js/compositionAPI/profile/useProfile'

export default {
  components: {
    BCard,
    BAlert,
    BButton,
  },
  setup() {
    const showAlert = ref(false)
    const profile = ref({})
    const { getProfileSingleByUid } = useProfile()

    const fetch = async () => {
      profile.value = await getProfileSingleByUid()
      if (!profile.value.isProfile) showAlert.value = true
    }
    onMounted(() => {
      fetch()
    })
    return {
      showAlert,
      profile,
      formatDateMinutes,
    }
  },
}
</script>
