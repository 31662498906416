<template>
  <div class="">
    <h3 class="mb-2">
      動画一覧
    </h3>
    <b-card
      v-if="dataList.length === 0"
    >
      <div class="">
        視聴できる動画はありません。
      </div>
      <div class="text-sub mt-1">
        <small>※動画の決済がお済みの場合、プロフィールの登録後、管理者から視聴権限が付与されますので、しばらくお待ちください。</small>
      </div>
    </b-card>
    <div v-else>
      <div
        v-for="(item, index) in dataList"
        :key="index"
        class=""
      >
        <b-card
          :img-src="categoryA.find(v => v.id === item.lessonInfo.id).picURI"
          img-height="200px"
        >
          <b-card-title>
            <div class="text-primary">
              {{ item.lessonInfo.labelName }}
            </div>
          </b-card-title>
          <b-card-text>
            <div class="mb-2">
              {{ showDescription(item) }}
            </div>
          </b-card-text>
          <div class="text-right">
            <b-button
              variant="outline-primary"
              :to="`/student/lesson/detail?id=${item.lessonId}`"
              size="sm"
            >
              <feather-icon
                icon="VideoIcon"
                class="mr-50"
              />
              <span>視聴する</span>
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useApply from '@/components/js/compositionAPI/apply/useApply'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import store from '@/store'

export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
  },
  setup() {
    const showAlert = ref(false)
    const dataList = ref([])
    const { getApplyAllByUid } = useApply()
    const {
      categoryA,
    } = useLesson()
    const fetch = async () => {
      dataList.value = await getApplyAllByUid({ uid: store.state.userBasic.uid })
    }
    const showDescription = item => {
      const { description } = categoryA.find(v => v.id === item.lessonId)
      return description || ''
    }
    onMounted(() => {
      fetch()
    })
    return {
      showAlert,
      showDescription,
      dataList,
      categoryA,
    }
  },
}
</script>
