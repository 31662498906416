<template>
  <div class="space-sm">
    <div class="">
      <h2>ダッシュボード</h2>
    </div>
    <div class="">
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body mt-1 mb-2">
          <div class="d-flex">
            <b-avatar
              size="40"
              src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
              variant="light-primary"
              class="mr-50"
            />
            <div class="">
              受講生のダッシュボードです。
            </div>
          </div>
        </div>
      </b-alert>
    </div>
    <hr>
    <DashBoadProfile />
    <hr>
    <DashBoadLessonList />
  </div>
</template>

<script>
import {
  BAlert, BAvatar,
} from 'bootstrap-vue'
import DashBoadProfile from '@/components/student/dashboad/components/DashBoadProfile.vue'
import DashBoadLessonList from '@/components/student/dashboad/components/DashBoadLessonList.vue'

export default {
  components: {
    BAlert,
    BAvatar,
    DashBoadProfile,
    DashBoadLessonList,
  },
}
</script>
